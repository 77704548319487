<template>
  <static-fullscreen-card>
    <template v-slot:header> </template>
    <template v-slot:title>
      <a-btn-add :icon="'fas fa-cog'" :title="'Пареметры отчета'" @click="showConfig = true" />
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main"> </portal>
    <a-table-f-sql
      v-if="configured && !showDetail"
      ref="table"
      :query="sql"
      :model="
        model.filter((el) => {
          return el.hide !== 'main';
        })
      "
      :useQuery="false"
      :defaults="{ paramName: 'accReportAccount', sort: {} }"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    >
      <template v-slot:footer>
        <slot name="footer">
          <div class="f-table-footer">
            <div class="f-table-row">
              <div style="margin: 6px 6px">
                {{ `Показано: ${$refs.table ? $refs.table.pager.count : 0};  Сумма: ${$refs.table ? $refs.table.pager.value : 0};` }}
              </div>
            </div>
          </div>
        </slot>
      </template>
    </a-table-f-sql>
    <a-table-f-sql
      v-if="configured && showDetail"
      ref="tableDetail"
      :query="sqlDetail"
      :model="model"
      :useQuery="false"
      @click="onClickRowDetail()"
      :defaults="{ paramName: 'accReportAccount', sort: {} }"
    >
      <template v-slot:footer>
        <slot name="footer">
          <div class="f-table-footer">
            <div class="f-table-row">
              <div style="margin: 6px 6px">{{ `Показано: ${$refs.tableDetail.pager.count};  Сумма: ${$refs.tableDetail.pager.value};` }}</div>
            </div>
          </div>
        </slot>
      </template>
    </a-table-f-sql>
    <ReportConfig v-if="showConfig" v-model="showConfig" :config="configReport" @apply="onConfig($event)" />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect } from "@/components/mixings";
import libsCash from "@/views/documents/libsCash";
export default {
  mixins: [getAccess, keyDetect, doubleClickDetect],
  components: {
    ReportConfig: () => import("@/views/dialogs/accReportConfigDialog"),
  },
  data() {
    return {
      idDoc: 0,
      idStore: 0,
      dateStart: new Date().addDays(-7).date,
      dateEnd: new Date().date,
      configured: false,
      showConfig: false,
      showEditDialog: false,
      showDetail: false,
      title: "",
      defaults: {
        paramName: "accCashBox",
        sort: {},
        pager: {
          page: 0,
          count: 0,
          limit: 10,
        },
      },
      cashList: null,
      cashbox: -1,
      m: this.$store.getters["config/get"].models.accReportAccount,

      configReport: null,
      sqlText: "",
      sqlTextDetail: null,
      detail: { subconto1: null, subconto2: null },
    };
  },
  created() {
    this.$root.title = `Отчет по счетам`;
    //this.getAccountList();
  },
  computed: {
    model() {
      let model = [
        { name: "docName", title: "Документ", type: "string", sortable: true, width: 250, hide: "main" },
        { name: "acc", title: "acc", type: "string", sortable: true, width: 50 },
        { name: "date_doc", title: "Дата", type: "datetime", sortable: true, width: 150, hide: "main" },
        { name: "sub1name", title: "Субконто 1", type: "string", sortable: true, width: 250 },
        { name: "sub2name", title: "Субконто 2", type: "string", sortable: true, width: 150 },
        { name: "val_in", title: "Сумма+", type: "number", sortable: true, width: 100 },
        { name: "val_out", title: "Сумма-", type: "number", sortable: true, width: 100 },
        { name: "value", title: "Итого", type: "number", sortable: true, width: 100 },
      ];
      return model;
    },
    sql() {
      let operations = this.$root.dirs.operationTable.filter((o) => {
        if ((o.debit || "").includes("50.") || (o.credit || "").includes("50.")) return true;
        return false;
      });
      let list = operations
        .map((o) => {
          return o.id;
        })
        .join();
      let group = "";
      let order = "sub1name desc";
      let where = "";
      let sql;
      let footer = [{ name: "value", title: "Сумма" }];
      // this.generateSQL(this.configReport, 0);
      sql = this.sqlText;
      //sql =  this.generateSQL(this.configReport);
      let res = { sql, order, group, where, footer };
      return res;
    },
    sqlDetail() {
      let group = "";
      let order = "sub1name desc";
      let where = "";
      let sql;
      let footer = [{ name: "value", title: "Сумма" }];
      // this.generateSQL(this.configReport, 0);
      sql = this.sqlTextDetail;
      //sql =  this.generateSQL(this.configReport);
      let res = { sql, order, group, where, footer };
      return res;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        // this.$refs.table.updateData();
      }
    },
  },
  methods: {
    generateTitle() {
      let d = this.configReport;
      this.$root.title = `Отчет "${d.title}" ${d.show_zero ? ", показывать 0" : ""}${this.showDetail ? " [Детали]" : ""}`;
    },
    generateSQL(data, detail = 0) {
      //console.log("generate SQL", data, detail);
      let sql = "";
      let subconto1 = data?.subconto_1 || 0;
      let subconto2 = null;
      if (detail) {
        subconto1 = this.detail.subconto1;
        if (data?.isSubconto2) subconto2 = this.detail.subconto2;
      }
      //console.log("generate SQL", data, detail, subconto1);
      let selectRes = detail
        ? `date_doc,base_name,base_id,subconto_1,subconto_2, value,1 AS 'rows', val_in, val_out,acc`
        : `null as 'base_name',null as 'base_id',subconto_1,subconto_2, SUM(VALUE) AS 'value',COUNT(1) AS 'rows', SUM(val_in) AS 'val_in', SUM(val_out) AS 'val_out' ,acc`;

      sql = `
select res.*, 
getSubcontoValue(res.acc,'subconto1',subconto_1) as 'sub1name' ,
getSubcontoValue(res.acc,'subconto2',subconto_2) as 'sub2name' ,
if(base_name is null,'',getDocName(base_name,base_id)) as 'docName' 
from (      
SELECT ${selectRes} FROM (
SELECT id, createdon,date_doc,base_name,base_id,credit_subconto_value_1 AS 'subconto_1', credit_account as 'acc',
${data?.isSubconto2 || detail ? "credit_subconto_value_2" : "null"} AS 'subconto_2',-value AS 'value', null as val_in,   value as val_out
FROM accounting_record 
WHERE credit_account LIKE '${data.type}%' ${subconto1 ? ` and credit_subconto_value_1 = ${subconto1}` : ""} ${
        data?.isSubconto2 && detail ? " and credit_subconto_value_2=" + subconto2 : ""
      }
UNION ALL
SELECT id, createdon,date_doc,base_name,base_id,debit_subconto_value_1 AS 'subconto_1', debit_account as 'acc',
${data?.isSubconto2 || detail ? "debit_subconto_value_2" : "null"} AS 'subconto_2',VALUE , value as val_in,  null as val_out
FROM accounting_record 
WHERE debit_account LIKE '${data.type}%' ${subconto1 ? ` and debit_subconto_value_1 = ${subconto1}` : ""} ${
        data?.isSubconto2 && detail ? " and debit_subconto_value_2=" + subconto2 : ""
      }

) AS t
${detail ? "" : "GROUP BY subconto_1, subconto_2"}
${detail || data?.show_zero ? " " : "HAVING SUM(VALUE)<>0"}
) as res
`;

      if (detail == 0) this.sqlText = sql;
      else this.sqlTextDetail = sql;
    },
    onConfig(e) {
      console.log("on config", e);
      this.dateStart = e?.dateStart;
      this.dateEnd = e?.dateEnd;
      this.configReport = e;
      //this.sqlText = e.sql;
      this.generateSQL(e, 0);
      this.generateTitle();
      this.showDetail = false;
      this.configured = true;
    },
    onUpdateData() {},
    onSingleClick(d) {},
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRowDetail(d) {
      this.showDetail = false;
      this.generateTitle();
      //this.showEditDialogFun(d.row.id);
    },
    onClickRow(d) {
      this.detail = { subconto1: d.row.subconto_1, subconto2: d.row.subconto_2 };
      this.generateSQL(this.configReport, d.row.subconto_1);
      this.showDetail = true;
      this.generateTitle();
      //this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idDoc = id;
      this.showEditDialog = true;
    },

    onConfigReport(type) {
      console.log("onConfigDocOut", type);

      return true;
    },
  },
};
</script>
